import React from "react";
import rocketImg from "../../../assets/svgs/rocket.svg";

export default function StopWastingTime() {
  return (
    <div className="stop-wasting-time-wrapper display-flex-column">
      <img className="rocket-image" src={rocketImg} alt="To the moon" />
      <div className="rocket-image-msg-header">Stop wasting time completing the same information, we do it for you</div>
      <div className="rocket-image-msg-body">
        You will never waste time manually filling out forms again, we learn how you fill out your forms and we fill it for you.
      </div>
    </div>
  );
}
