import React, { useState } from "react";
import "./forgotPassword.css";
import wideLogo from "../../assets/pngs/fillbaz-logo-wide.png";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import { useMutation } from "@tanstack/react-query";
import apiServices from "../../services/services";
import useAntdMessage from "../../customHooks/useAntdMessage";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const showMessage = useAntdMessage();
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordMatch, setUserPasswordMatch] = useState("");

  const changePasswordMutation = useMutation((newPassword) => apiServices.setNewPassword(newPassword), {
    onSuccess: (data) => {
      showMessage(data?.severity || "sucess", data?.message || "Password changed successfully", () => {
        if (data?.severity && data.severity !== "warning" && data.severity !== "error") navigate("/reset-password-success");
      });
    },
    onError: () => {
      showMessage("error", "Something went wrong while changing password");
    }
  });

  async function handleChangePassword(e) {
    e.preventDefault();
    const validationStatus = apiServices.signup(userPassword, userPasswordMatch);

    if (validationStatus?.isValid) {
      changePasswordMutation.mutate(userPassword);
    } else {
      showMessage("error", validationStatus?.message || "Something went wrong!");
    }
  }

  return (
    <div className="no-full-page-content">
      <div className="no-full-page-wrapper">
        <img className="forgot-password-element fillbaz-logo-header" src={wideLogo} alt="email verification fillbaz logo header" />
        <div className="forgot-password-element forgot-password-message">
          Please enter a new password. Your new password must be different from previous used password.
        </div>
        <form className="forgot-password-element display-flex-column">
          <Input.Password
            className="new-password-input"
            placeholder="Create password"
            required
            onChange={(e) => setUserPassword(e.target.value)}
          />
          <Input.Password
            className="new-password-input"
            placeholder="Re-enter your password"
            required
            onChange={(e) => setUserPasswordMatch(e.target.value)}
          />

          <Button className="new-password-submit-btn" type="primary" onClick={(e) => handleChangePassword(e)}>
            {changePasswordMutation.isLoading ? "LOADING" : "Change Password"}
          </Button>
        </form>
      </div>
    </div>
  );
}
