import { Modal } from "antd";
import React from "react";
import apiServices from "../../../services/services";
import { useMutation } from "@tanstack/react-query";
import useAntdMessage from "../../../customHooks/useAntdMessage";

export default function FieldOptionDeleteModal({
  isFieldOptionDeleteModelOpen,
  setIsFieldOptionDeleteModelOpen,
  value,
  fieldKey,
  friendlyText,
  fieldValues,
  setFieldValues
}) {
  const showMessage = useAntdMessage();
  const deleteFieldValueOption = useMutation((deletedOption) => apiServices.deleteUserFieldOption(deletedOption), {
    onSuccess: () => {
      const updatedFieldValues = fieldValues.filter((option) => option.value !== value);
      setFieldValues(updatedFieldValues);
    },
    onError: () => {
      showMessage("error", "Something went wrong deleting field value, please try again later.");
    }
  });

  const handleOk = () => {
    deleteFieldValueOption.mutate({ value, key: fieldKey });
    setIsFieldOptionDeleteModelOpen(false);
  };

  const handleCancel = () => {
    setIsFieldOptionDeleteModelOpen(false);
  };

  return (
    <Modal okText="Delete" title="Deleting an option!" open={isFieldOptionDeleteModelOpen} onOk={handleOk} onCancel={handleCancel}>
      Are you sure that you want to delete <b>{value}</b> as your <b>{friendlyText}</b>?
    </Modal>
  );
}
