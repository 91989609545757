import axios from "axios";

export function setInterceptors(toggleLoaderOn, toggleLoaderOff, onTokenExpire, token) {
  axios.interceptors.request.use(async (config) => {
    if (!config.preventLoading) toggleLoaderOn();

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      toggleLoaderOff();
      // TODO - add error handling

      // if (response && response.data) {
      //   if (response.data.needsLogin) {
      //     const stationName = localStorage.getItem("stationName");
      //     localStorage.clear();
      //     localStorage.setItem("stationName", stationName);
      //     document.location.href = "/sessions/login";
      //     return;
      //   } else if (response.data.err) {
      //     Swal.fire("", generalErrMsg, "warning");
      //   }
      // }

      return response;
    },
    (error) => {
      toggleLoaderOff();
      if (error.request) {
        if (error.code === "ERR_NETWORK") onTokenExpire(true);
        const errorCode = error.response?.data?.errorCode;
        if (error.request.status === 401 && (errorCode === "EXPIRED_TOKEN" || errorCode === "BAD_TOKEN")) {
          onTokenExpire();
        }
      }

      // not allowed to ACCESS something specific(manger) error.request.status === 403
      // if (error.request && error.request.status === 429) {
      //   Swal.fire("", "Too many requests made", "warning").then(() => {
      //     window.location.href = "/";
      //   });
      // } else {
      //   Swal.fire("", generalErrMsg, "warning");
      // }
      return Promise.reject(error);

      // localStorage.clear();
      // document.location.href = "/guest/sign-in";
      // if (error.response.status === 404) {
      // history.push("/not-found");
      // }
    }
  );
}
