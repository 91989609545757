import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import { personalAreaMenuItems } from "../services/utils";

const PersonalAreaNavMenu = ({ currentNavItem }) => {
  const navigate = useNavigate();

  const onClick = (e) => {
    if (e?.key) navigate(`/${e.key}`);
  };

  return (
    <div className="menu-container">
      <Menu
        onClick={onClick}
        style={{
          width: 210
        }}
        defaultSelectedKeys={[currentNavItem]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        items={personalAreaMenuItems(<AppstoreOutlined />)}
      />
    </div>
  );
};

export default PersonalAreaNavMenu;
