import React, { useState } from "react";
import LoginSignupBtns from "../PageAfterInstall/subcomponents/LoginSignupBtns";
import fillbazLogo from "../../assets/pngs/fillbaz-logo-wide.png";
import "./ignoredPages.css";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Pagination } from "antd";
import { renderedItemsPerPage } from "../../config";
import { useMutation } from "@tanstack/react-query";
import apiServices from "../../services/services";
import { setUserIgnoredFields } from "../../store/reducers/userSettings";
import Search from "antd/es/input/Search";
import useAntdMessage from "../../customHooks/useAntdMessage";
import IgnoredPageRow from "./subcomponents/IgnoredPageRow";

export default function IgnoredPages() {
  const dispatch = useDispatch();
  const showMessage = useAntdMessage();
  const { userIgnoredPages, userIgnoredPagesAmount } = useSelector((state) => state.userSettings);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchModeOn, setSearchModeOn] = useState(false);

  const getMoreIgnoredPages = useMutation((pageNumber) => apiServices.getMoreIgnoredPages(pageNumber), {
    onSuccess: (data) => {
      const { ignoredPagesForCurrentPage, pageNumber } = data;
      setCurrentPage(pageNumber);

      setSearchModeOn(false);
      dispatch(setUserIgnoredFields(ignoredPagesForCurrentPage));
    },
    onError: (err) => {
      showMessage("error", "Something went wrong while fetching next page data, please try again later.");
    }
  });

  const getIgnoredPagesOnSearch = useMutation((searchText) => apiServices.getIgnoredPagesOnSearch(searchText), {
    onSuccess: (data) => {
      setSearchModeOn(true);
      dispatch(setUserIgnoredFields(data));
    },
    onError: (err) => {
      showMessage("error", "Something went wrong while executing your search, please try again later.");
    }
  });

  const handlePageChange = (page) => {
    getMoreIgnoredPages.mutate(page);
  };

  const handleFieldSearch = (searchText) => {
    if (searchText.length > 1) {
      getIgnoredPagesOnSearch.mutate(searchText);
    } else {
      getMoreIgnoredPages.mutate(1);
    }
  };

  return (
    <div className="ignored-pages-wrapper display-flex-column">
      <div>
        <div className="ignored-pages-header-wrapper">
          <img className="my-info-stored-fillbaz-logo" src={fillbazLogo} alt="fillbaz-logo" />
          <LoginSignupBtns currentNavItem={"ignored-websites"} />
        </div>
        <div className="display-flex-row">
          <h3 className="ignored-pages-h3 apply-main-color">Ignored Websites</h3>
          <Search placeholder="Search" style={{ width: 200 }} onSearch={(searchText) => handleFieldSearch(searchText)} />
        </div>

        <div>
          {userIgnoredPages?.length ? (
            userIgnoredPages.map((ignoredPage) => (
              <div className="display-flex-row ignored-page-row" key={ignoredPage?.fullUrl}>
                <IgnoredPageRow ignoredPage={ignoredPage} />
              </div>
            ))
          ) : (
            <Empty className="no-field-found-msg" description={"No ignored pages has been found"} />
          )}
        </div>
      </div>
      {searchModeOn ? (
        <></>
      ) : (
        <Pagination
          className="ignored-pages-pagination"
          simple
          total={userIgnoredPagesAmount}
          showSizeChanger={false}
          current={currentPage}
          pageSize={renderedItemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
}
