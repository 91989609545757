import React, { useEffect, useState } from "react";
import "./emailConfirmed.css";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getEmailConfirmedComponentHeaderAndMessage, handleLoginSignupComponentRender } from "../../services/utils";
import wideLogo from "../../assets/pngs/fillbaz-logo-wide.png";
import { Button } from "antd";
import apiServices from "../../services/services";

export default function EmailConfirmed() {
  const { isPhoneView } = useSelector((state) => state.appSettings);
  const [resendEmailBtnVisibilityCountdown, setResendEmailBtnVisibilityCountdown] = useState(60);
  const { data, isLoading, isError } = useQuery({ queryKey: ["verifyUser"], queryFn: apiServices.verifyUser });

  const isEmailConfirmed = data?.isEmailConfirmed;
  const isUserAlreadyConfirmed = data?.isUserAlreadyConfirmed;

  const { messageHeader, messageText } = getEmailConfirmedComponentHeaderAndMessage(isError, isEmailConfirmed, isLoading);

  useEffect(() => {
    if (resendEmailBtnVisibilityCountdown > 0) {
      const btnVisibilityTimer = setInterval(() => {
        setResendEmailBtnVisibilityCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000); // 1000 milliseconds = 1 second

      return () => {
        clearInterval(btnVisibilityTimer);
      };
    }
  }, [resendEmailBtnVisibilityCountdown]);

  function handleResendVerificationEmail() {
    apiServices.reSendVerificationEmail();
  }

  return (
    <div className="no-full-page-content">
      <div className="no-full-page-wrapper">
        <img className="verify-email-element fillbaz-logo-header" src={wideLogo} alt="email verification fillbaz logo header" />
        <h2 className="verify-email-element apply-main-color email-confirmed-header">{messageHeader}</h2>
        <div className="verify-email-element email-confirmed-message">{messageText}</div>
        <div className="verify-email-element">
          {!isPhoneView && !isError && (
            <Button className="email-confirmed-page-btn" type="primary" onClick={() => handleLoginSignupComponentRender("login")}>
              Continue to Login
            </Button>
          )}
          {resendEmailBtnVisibilityCountdown > 0 && !isEmailConfirmed && (
            <Button
              className="email-confirmed-page-btn"
              disabled={isUserAlreadyConfirmed ? isUserAlreadyConfirmed : false}
              type="primary"
              onClick={handleResendVerificationEmail}
            >
              {isUserAlreadyConfirmed
                ? `Email already confirmed ${resendEmailBtnVisibilityCountdown}`
                : `Resend Verification Email ${resendEmailBtnVisibilityCountdown}`}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
