import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderOn } from "../store/reducers/appSettings";

function useIframeInjectionChecker() {
  const dispatch = useDispatch();
  const { fillbazIframeSrc } = useSelector((state) => state.appSettings);

  useEffect(() => {
    let iframeLoadedTracker = false;
    dispatch(setLoaderOn(true));
    let timer = setTimeout(() => {
      if (!iframeLoadedTracker) {
        dispatch(setLoaderOn(false));
      }
    }, 5000);

    const interval = setInterval(() => {
      if (fillbazIframeSrc) {
        clearInterval(interval);
        dispatch(setLoaderOn(false));

        iframeLoadedTracker = true;
        clearTimeout(timer);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [fillbazIframeSrc]);

  return;
}

export default useIframeInjectionChecker;
