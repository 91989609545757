import React from "react";
import "./pageAfterInstall.css";
import CongratsInstallingFillbaz from "./subcomponents/CongratsInstallingFillbaz";
import WhyToUseFillbaz from "./subcomponents/WhyToUseFillbaz";
import StopWastingTime from "./subcomponents/StopWastingTime";
import CommentsSection from "./subcomponents/CommentsSection";
import LoginSignupBtns from "./subcomponents/LoginSignupBtns";

export default function PageAfterInstall() {
  return (
    <div className="options-page-content">
      <LoginSignupBtns />
      <div className="options-page-main-content-wrapper">
        <CongratsInstallingFillbaz />
        <WhyToUseFillbaz />
        <StopWastingTime />
        <CommentsSection />
      </div>
    </div>
  );
}
