const apiUrlEndpoint = window.location.host.includes("fillbaz") ? "https://api.fillbaz.com/" : "http://localhost:8050/";

const endpoints = {
  verifyUser: apiUrlEndpoint + "auth/verify-user",
  reSendVerificationEmail: apiUrlEndpoint + "auth/resend-verification-email",
  setNewPassword: apiUrlEndpoint + "auth/change-password",
  getCompleteUserInfo: apiUrlEndpoint + "user/get-all-user-info",
  getNextPageFields: apiUrlEndpoint + "user/get-next-page-fields",
  getFieldsBySearch: apiUrlEndpoint + "user/get-fields-by-search",
  deleteUserFieldOption: apiUrlEndpoint + "user/delete-field-option",
  addNewUserFieldValue: apiUrlEndpoint + "user/change-field",
  addIgnoredPage: apiUrlEndpoint + "user/deactivate-notifications",
  removeIgnoredPage: apiUrlEndpoint + "user/activate-notifications",
  getMoreIgnoredPages: apiUrlEndpoint + "user/get-more-ignored-pages",
  getIgnoredPagesBySearch: apiUrlEndpoint + "user/get-ignored-pages-on-search",
  changeAccountInformation: apiUrlEndpoint + "user/change-account-info",
  getAccountInformation: apiUrlEndpoint + "user/get-account-info",
  getFieldValueByFieldKey: apiUrlEndpoint + "user/get-fields-by-field-key"
};

const renderedItemsPerPage = 9;

const fillbazExtensionChromeWebstoreUrl = "https://chromewebstore.google.com/detail/fillbaz/oobejjkilclphlbiaaajdidlhdpaohjj";

module.exports = {
  endpoints,
  renderedItemsPerPage,
  fillbazExtensionChromeWebstoreUrl
};
