import { Input, Modal, Select } from "antd";
import React, { useState } from "react";
import apiServices from "../../../services/services";
import { useMutation } from "@tanstack/react-query";
import useAntdMessage from "../../../customHooks/useAntdMessage";
import { useDispatch, useSelector } from "react-redux";
import { setUserFields } from "../../../store/reducers/userSettings";

export default function AddNewFieldModal({ addNewFieldModalOn, setAddNewFieldModalOn, setSearchModeOn }) {
  const showMessage = useAntdMessage();
  const dispatch = useDispatch();

  const { fieldsDictionary } = useSelector((state) => state.appSettings);
  const { userProfiles } = useSelector((state) => state.userSettings);
  const defaultSelectedProfile = userProfiles.find((profile) => profile.isMainProfile);
  const fieldsDictionaryFormatted = fieldsDictionary.map((field) => ({ value: field.key, label: field.friendlyText }));

  const [selectedFieldKey, setSelectedFieldKey] = useState(null);
  const [addedFieldValue, setAddedFieldValue] = useState("");

  const handleAddNewField = useMutation((newFieldData) => apiServices.addNewUserFieldValue(newFieldData, defaultSelectedProfile), {
    onSuccess: () => {
      const formattedObjectFromDictionaryOfSelectedFieldKey = fieldsDictionaryFormatted.find((field) => field.value === selectedFieldKey);
      const friendlyTextOfSelectedKey = formattedObjectFromDictionaryOfSelectedFieldKey?.label;
      const userFieldsAtTheCurrentPage = [{ friendlyText: friendlyTextOfSelectedKey, key: selectedFieldKey, values: [addedFieldValue] }];
      dispatch(setUserFields(userFieldsAtTheCurrentPage));
      setSearchModeOn(true);
      handleRemoveModal();
    },
    onError: () => {
      showMessage("error", "Something went wrong while adding the new field. Please try again later.");
    }
  });

  const handleSaveNewField = () => {
    if (selectedFieldKey && addedFieldValue) {
      handleAddNewField.mutate({ key: selectedFieldKey, value: addedFieldValue });
    } else {
      showMessage("warning", "Both field name and field value should be entered");
    }
  };

  const handleRemoveModal = () => {
    setAddNewFieldModalOn(false);
    setSelectedFieldKey(null);
    setAddedFieldValue("");
  };

  const getFieldValuesByFieldKey = useMutation((fieldKey) => apiServices.getFieldValuesByFieldKey(fieldKey), {
    onSuccess: (data) => {
      const { fieldKey, userFields: userFieldsByKey } = data;
      if (userFieldsByKey?.length) {
        setSearchModeOn(true);
        showMessage("warning", "This field already exists!");
        dispatch(setUserFields(userFieldsByKey));
        setAddNewFieldModalOn(false);
        setSelectedFieldKey(null);
        setAddedFieldValue("");
      } else {
        setSelectedFieldKey(fieldKey);
      }
    },
    onError: (err) => {
      showMessage("error", "Something went wrong while executing your search, please try again later.");
    }
  });

  const handleFieldKeyChange = (selectedFieldKey) => {
    getFieldValuesByFieldKey.mutate(selectedFieldKey);
  };

  return (
    <Modal okText="Save" title="Add New Field" open={addNewFieldModalOn} onOk={handleSaveNewField} onCancel={handleRemoveModal}>
      <div className="display-flex-column">
        <div className="add-new-field-modal-column-wrapper">
          <div>Field:</div>
          <Select
            className="add-new-field-modal-select-component full-width"
            value={selectedFieldKey}
            showSearch
            placeholder="Select a field - Ex: Full-name"
            optionFilterProp="children"
            onChange={handleFieldKeyChange}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={fieldsDictionaryFormatted}
          />
        </div>
        <div className="add-new-field-modal-column-wrapper">
          <div>Value:</div>
          <Input
            placeholder="Type your field info - Ex: John Doe"
            value={addedFieldValue}
            onChange={(e) => setAddedFieldValue(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
}
