import React, { useState } from "react";
import { Button, Input } from "antd";
import { useMutation } from "@tanstack/react-query";
import apiServices from "../../../services/services";
import useAntdMessage from "../../../customHooks/useAntdMessage";
import IgnoredPageModal from "./IgnorePageModal";

export default function IgnoredPageRow({ ignoredPage }) {
  const { fullUrl } = ignoredPage;
  const showMessage = useAntdMessage();
  const [isPageUnignored, setsIsPageUnignored] = useState(false);
  const [isIgnorePageModalOpen, setIsIgnorePageModalOpen] = useState(false);

  const ignoreOrUnignorePage = useMutation(({ fullUrl, isIgnored }) => apiServices.addOrRemoveIgnoredPages(fullUrl, isIgnored), {
    onSuccess: (data) => {
      const { isIgnored } = data;
      setsIsPageUnignored(!isIgnored);
      isIgnorePageModalOpen && setIsIgnorePageModalOpen(false);
    },
    onError: () => {
      showMessage("error", "Something went wrong, please try again later.");
    }
  });

  const handleUnignorePage = () => {
    ignoreOrUnignorePage.mutate({ fullUrl, isIgnored: false });
  };

  const handleIgnorePage = () => {
    ignoreOrUnignorePage.mutate({ fullUrl, isIgnored: true });
  };

  return (
    <>
      <div className="ignored-page-url-input display-flex-row">{ignoredPage.fullUrl}</div>
      {isPageUnignored ? (
        <Button onClick={() => setIsIgnorePageModalOpen(true)}>Ignore the website</Button>
      ) : (
        <Button onClick={handleUnignorePage}>Unignore the website</Button>
      )}
      {isIgnorePageModalOpen ? (
        <IgnoredPageModal
          isIgnorePageModalOpen={isIgnorePageModalOpen}
          setIsIgnorePageModalOpen={setIsIgnorePageModalOpen}
          handleIgnorePage={handleIgnorePage}
          fullUrl={fullUrl}
        />
      ) : (
        <></>
      )}
    </>
  );
}
