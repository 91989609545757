import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setIsLoggedIn,
  setUserAccountInfo,
  setUserAuthToken,
  setUserFields,
  setUserFieldsAmount,
  setUserIgnoredFields,
  setUserIgnoredPagesAmount,
  setUserProfiles
} from "../store/reducers/userSettings";
import { setFieldsDictionary, setFillbazIframeSrc, setLoaderOn } from "../store/reducers/appSettings";
import { setInterceptors } from "../services/interceptorService";
import apiServices from "../services/services";
import useAntdMessage from "./useAntdMessage";

function useBrowserMessageListener() {
  const dispatch = useDispatch();
  const showMessage = useAntdMessage();
  const toggleLoaderOn = () => dispatch(setLoaderOn(true));
  const toggleLoaderOff = () => dispatch(setLoaderOn(false));

  const messageHandlers = {
    handleTokenForTheLoggedInUser: async (loggedinUserAuth) => {
      const loggedinUserAuthData = loggedinUserAuth?.data;
      let token, fillbazIframeSrc;
      if (loggedinUserAuthData) {
        token = loggedinUserAuthData.token;
        fillbazIframeSrc = loggedinUserAuthData.fillbazIframeSrc;
      }

      dispatch(setUserAuthToken(token));
      dispatch(setFillbazIframeSrc(fillbazIframeSrc));
      toggleLoaderOff();

      const onTokenExpire = (isNetworkError) => {
        dispatch(setIsLoggedIn(false));
        if (isNetworkError) showMessage("error", "Network Error, please try again later");
      };

      setInterceptors(toggleLoaderOn, toggleLoaderOff, onTokenExpire, token);

      const completeUserDataFromDb = await apiServices.getCompleteUserInfo();

      if (completeUserDataFromDb?.success && completeUserDataFromDb?.data) {
        const { ignoredPages, accountInfo, userFields, fieldsDictionary, totalUserFieldsCount, userProfiles, ignoredPagesCount } =
          completeUserDataFromDb.data;

        if (totalUserFieldsCount) dispatch(setUserFieldsAmount(totalUserFieldsCount));
        if (ignoredPagesCount) dispatch(setUserIgnoredPagesAmount(ignoredPagesCount));
        if (userFields?.length) dispatch(setUserFields(userFields));
        if (ignoredPages?.length) dispatch(setUserIgnoredFields(ignoredPages));
        if (accountInfo) dispatch(setUserAccountInfo(accountInfo));
        if (fieldsDictionary) dispatch(setFieldsDictionary(fieldsDictionary));
        if (userProfiles?.length) dispatch(setUserProfiles(userProfiles));

        dispatch(setIsLoggedIn(true));
      }
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.source === window.parent) {
        const { message } = event.data;

        // Call the appropriate message handler based on the message type
        switch (message) {
          case "SENDING_AUTH_TOKEN_TO_DASHBOARD":
            messageHandlers.handleTokenForTheLoggedInUser(event.data);
            break;
          case "FILLBAZ_LOGOUT_FROM_EXTENSION":
            dispatch(setIsLoggedIn(false));
            break;
          default:
            // Handle unknown message types if needed
            break;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [dispatch]);

  return {
    dispatch
  };
}

export default useBrowserMessageListener;
