export const hardCodedComments = [
  {
    name: "Sofia Rodriguez",
    comment:
      "I recently tried out this autofilling extension, and it has greatly improved my browsing experience! The autofill feature is incredibly convenient and saves me a lot of time when filling out forms online. I highly recommend it to anyone looking to streamline their browsing.",
    rating: 5
  },
  {
    name: "Liam Chen",
    comment:
      "This autofilling extension is a game-changer! I love how easy it is to use, and it works seamlessly across all websites. It has definitely made my online activities more efficient.",
    rating: 5
  },
  {
    name: "Isabella Khan",
    comment:
      "I've been using this autofilling extension for a while now, and I must say, it's fantastic! It's reliable, fast, and has simplified my online transactions. Definitely worth giving it a try!",
    rating: 5
  },
  {
    name: "Mateo Gupta",
    comment:
      "The autofill feature of this extension is incredibly useful. It accurately fills in my information, saving me time and hassle when shopping or filling out forms online. It's definitely made my life easier.",
    rating: 4.5
  },
  {
    name: "Olivia Patel",
    comment:
      "I'm impressed by how efficient this autofilling extension is. It makes online shopping a breeze by quickly filling in my payment and shipping details. Overall, a great tool to have for anyone who spends a lot of time online.",
    rating: 5
  },
  {
    name: "Noah Nguyen",
    comment:
      "I recently started using this autofilling extension, and I'm already loving it! It's intuitive to use and has significantly sped up my online activities. Highly recommended!",
    rating: 4.5
  },
  {
    name: "Emma Kim",
    comment:
      "This autofilling extension has been a game-changer for me. It saves me so much time by automatically filling in my information on websites. Plus, it's customizable and easy to use. Definitely one of my favorite browser extensions!",
    rating: 5
  },
  {
    name: "Lucas Singh",
    comment:
      "I highly recommend this autofilling extension to anyone who wants to simplify their online tasks. It's reliable, user-friendly, and has greatly improved my productivity.",
    rating: 5
  },
  {
    name: "Sophia Yamamoto",
    comment:
      "This autofilling extension is a must-have for anyone who spends a lot of time online. It's incredibly convenient and saves me so much time when filling out forms or making purchases. I couldn't imagine browsing without it!",
    rating: 5
  },
  {
    name: "Jackson Ali",
    comment:
      "I've been using this autofilling extension for a while now, and it's been a huge help. It accurately fills in my information across various websites, making my online activities much smoother. Definitely worth trying out.",
    rating: 4.5
  },
  {
    name: "Amelia Wu",
    comment:
      "The autofill feature of this extension is fantastic. It's reliable and saves me a lot of time when filling out forms online. I highly recommend it to anyone looking to streamline their online experience.",
    rating: 5
  },
  {
    name: "Aiden Wong",
    comment:
      "I'm impressed by how seamlessly this autofilling extension works. It's intuitive to use and has made my online activities more efficient. I would definitely recommend it to others.",
    rating: 5
  },
  {
    name: "Mia Kimura",
    comment:
      "This autofilling extension has made my online transactions so much easier. It accurately fills in my information, saving me time and frustration. I'm very happy with it.",
    rating: 4.5
  },
  {
    name: "Ethan Kim",
    comment:
      "I recently started using this autofilling extension, and I'm already impressed by how well it works. It's easy to set up and has made filling out online forms a breeze. I highly recommend it!",
    rating: 4.5
  },
  {
    name: "Harper Nguyen",
    comment:
      "I've been using this autofilling extension for a few weeks now, and it's been a game-changer. It saves me so much time when shopping online by automatically filling in my payment and shipping details. Definitely worth checking out!",
    rating: 5
  },
  {
    name: "Avery Ito",
    comment:
      "The autofill feature of this extension is fantastic. It's accurate and reliable, and it saves me a lot of time when filling out forms online. I would highly recommend it to anyone looking to streamline their online tasks.",
    rating: 4.5
  },
  {
    name: "Liam Yamaguchi",
    comment:
      "This autofilling extension has been incredibly helpful for me. It's easy to use and has made my online activities more efficient. I would definitely recommend it to others.",
    rating: 5
  },
  {
    name: "Mia Patel",
    comment:
      "I recently started using this autofilling extension, and it's been a game-changer. It saves me so much time by automatically filling in my information on websites. Plus, it's customizable and easy to use. Definitely one of my favorite browser extensions!",
    rating: 5
  },
  {
    name: "Charlotte Suzuki",
    comment:
      "I've been using this autofilling extension for a while now, and I'm impressed by how well it works. It's reliable and accurate, and it saves me a lot of time when filling out forms online. Highly recommended!",
    rating: 4.5
  },
  {
    name: "Elijah Nguyen",
    comment:
      "This autofilling extension has made my online activities so much easier. It accurately fills in my information, saving me time and frustration. I'm very happy with it.",
    rating: 5
  },
  {
    name: "Aria Tanaka",
    comment:
      "I recently started using this autofilling extension, and I'm already impressed by how well it works. It's intuitive to use and has made filling out online forms a breeze. I highly recommend it!",
    rating: 4.5
  },
  {
    name: "Logan Lee",
    comment:
      "The autofill feature of this extension is fantastic. It's reliable and accurate, and it saves me a lot of time when filling out forms online. I would highly recommend it to anyone looking to streamline their online tasks.",
    rating: 5
  },
  {
    name: "Evelyn Yamamoto",
    comment:
      "I've been using this autofilling extension for a few weeks now, and it's been a game-changer. It saves me so much time when shopping online by automatically filling in my payment and shipping details. Definitely worth checking out!",
    rating: 5
  },
  {
    name: "Lincoln Chen",
    comment:
      "This autofilling extension has been incredibly helpful for me. It's easy to use and has made my online activities more efficient. I would definitely recommend it to others.",
    rating: 5
  },
  {
    name: "Zoey Kimura",
    comment:
      "I recently started using this autofilling extension, and it's been a game-changer. It saves me so much time by automatically filling in my information on websites. Plus, it's customizable and easy to use. Definitely one of my favorite browser extensions!",
    rating: 5
  }
];

export const breakpoints = {
  xs: "(max-width: 575px)",
  sm: "(min-width: 576px) and (max-width: 767px)",
  md: "(min-width: 768px) and (max-width: 991px)",
  lg: "(min-width: 992px) and (max-width: 1199px)",
  xl: "(min-width: 1200px)"
};

export function injectExtensionFont() {
  const googleFontText1 = document.createElement("link");
  googleFontText1.setAttribute("rel", "preconnect");
  googleFontText1.setAttribute("href", "https://fonts.googleapis.com");

  const googleFontText2 = document.createElement("link");
  googleFontText2.setAttribute("rel", "preconnect");
  googleFontText2.setAttribute("href", "https://fonts.gstatic.com");
  googleFontText2.setAttribute("crossorigin", "true");

  const googleFontText3 = document.createElement("style");
  const innerHTMLForFonts =
    '@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");';
  googleFontText3.innerHTML = innerHTMLForFonts;

  document.head.appendChild(googleFontText1);
  document.head.appendChild(googleFontText2);
  document.head.appendChild(googleFontText3);
}

export function getUrlParam(url, paramName) {
  const params = new URLSearchParams(new URL(url).search);
  return params.get(paramName);
}

export function handleLoginSignupComponentRender(page) {
  let retries = 3;

  function sendMessageToIframe() {
    const fillbazIframe = document.getElementById("chrome-embed");
    if (fillbazIframe) {
      fillbazIframe.contentWindow.postMessage({ message: "OPEN_LOGIN_OR_SIGNUP_PAGE", data: page }, "*");
    } else {
      retries--;
      if (retries > 0) {
        setTimeout(sendMessageToIframe, 3000); // Retry after 3 seconds
      }
    }
  }

  sendMessageToIframe();
}

export function getEmailConfirmedComponentHeaderAndMessage(isError, isEmailConfirmed, isLoading) {
  if (isError) {
    return { messageHeader: "Error", messageText: "Something went Wrong" };
  } else if (isEmailConfirmed) {
    return {
      messageHeader: "We’re so excited to have you join Fillbaz!",
      messageText: (
        <>
          Your email address has been successfully confirmed, marking the first step towards an exciting journey with us.
          <br />
          <br />
          There's no need for any extra actions on your part – you're all set to dive into the fantastic features of our extension. Feel
          free to log in now to explore and experience our extension firsthand.
        </>
      )
    };
  } else if (!isLoading && !isError && !isEmailConfirmed) {
    return {
      messageHeader: "Invalid Token",
      messageText: (
        <>
          We're sorry, the token provided is not valid for account verification. This might be due to one of the following reasons:
          <br />
          <b>1-</b> The verification link has already been used to verify this account. If you've already verified your account, you can log
          in using your credentials.
          <br />
          <b>2-</b> The verification link has expired. Please request a new verification link to continue.
          <br /> <br />
          If you're still experiencing issues, please contact our support team for further assistance. We apologize for any inconvenience
          and are here to help you get your account verified promptly.
        </>
      )
    };
  }

  return { messageHeader: "", messageText: "" };
}

export function getPersonalAreaItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}
export const personalAreaMenuItems = (icon) => [
  getPersonalAreaItem("Personal Area", "sub2", icon, [
    getPersonalAreaItem("Main Page", "welcome"),
    getPersonalAreaItem("My Account", "my-account"),
    getPersonalAreaItem("My Information Stored", "my-info-stored"),
    getPersonalAreaItem("Ignored Websites", "ignored-websites")
  ])
];
