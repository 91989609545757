import axios from "axios";
import { getUrlParam } from "./utils";
import { endpoints } from "../config";

const apiServices = {
  verifyUser: async () => {
    const fullUrl = window.location.href;
    const token = getUrlParam(fullUrl, "token");
    const headers = { Authorization: `Bearer ${token}` };
    let response;
    if (token) response = await axios.get(endpoints.verifyUser, { headers });

    if (!response?.statusText === "OK") {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    return { isEmailConfirmed: response?.data?.isTokenValid, isUserAlreadyConfirmed: response?.data?.isUserAlreadyConfirmed };
  },
  reSendVerificationEmail: () => {
    const fullUrl = window.location.href;
    const token = getUrlParam(fullUrl, "token");
    const headers = { Authorization: `Bearer ${token}` };
    axios.get(endpoints.reSendVerificationEmail, { headers });
  },
  setNewPassword: async (password) => {
    const fullUrl = window.location.href;
    const token = getUrlParam(fullUrl, "token");
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const passwordChangeStatus = await axios.post(endpoints.setNewPassword, { password }, { headers });
      return passwordChangeStatus?.data;
    } catch (error) {
      return {
        isValid: false,
        message: "something went wrong while changing password",
        severity: "error"
      };
    }
  },
  getCompleteUserInfo: async () => {
    try {
      const res = await axios.get(endpoints.getCompleteUserInfo);
      const completeUserInfo = res?.data?.completeUserInfo;
      return { success: true, data: completeUserInfo };
    } catch (error) {
      return { success: false, error };
    }
  },
  signup: (password, confirmPassword) => {
    // check if passwords match
    if (password !== confirmPassword) {
      return {
        isValid: false,
        message: "passwords do not match",
        severity: "error"
      };
    }

    // check if password has at least 6 characters
    if (password.length < 6) {
      return {
        isValid: false,
        message: "password must be at least 6 characters long",
        severity: "warning"
      };
    }

    // check if password contains at least one uppercase letter, one number, and one special character
    const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
    if (!pattern.test(password)) {
      return {
        isValid: false,
        message: "password must contain at least one uppercase letter, one number, and one special character",
        severity: "warning"
      };
    }

    // password is valid
    return {
      isValid: true,
      message: "Success!",
      severity: "success"
    };
  },
  getNextPageFields: async (pageNumber) => {
    const res = await axios.post(endpoints.getNextPageFields, { pageNumber });
    const userFieldsForCurrentPage = res?.data?.userFieldsOfCurrentPage;
    return { userFieldsForCurrentPage, pageNumber };
  },
  getUserFieldsForSearch: async (searchText) => {
    const res = await axios.post(endpoints.getFieldsBySearch, { searchText });
    const userFieldsForSearch = res?.data?.userFieldsForSearch;
    return userFieldsForSearch;
  },
  deleteUserFieldOption: async (deletedOption) => {
    const res = await axios.post(endpoints.deleteUserFieldOption, { ...deletedOption }, { preventLoading: true });
    return res?.data?.success;
  },
  addNewUserFieldValue: async (newFieldData, defaultSelectedProfile) => {
    const defaultSelectedProfileId = defaultSelectedProfile?.id;
    const res = await axios.post(
      endpoints.addNewUserFieldValue,
      {
        ...newFieldData,
        url: "Fillbaz Dashboard",
        profileId: defaultSelectedProfileId
      },
      { preventLoading: true }
    );
    return res?.data?.success;
  },
  addOrRemoveIgnoredPages: async (pageUrl, isIgnored) => {
    const endpointToCall = isIgnored ? endpoints.addIgnoredPage : endpoints.removeIgnoredPage;
    const serverRes = await axios.post(endpointToCall, { fullUrl: pageUrl }, { preventLoading: true });
    if (serverRes?.data?.success) {
      return { data: serverRes?.data, isIgnored };
    }
  },
  getMoreIgnoredPages: async (pageNumber) => {
    const res = await axios.post(endpoints.getMoreIgnoredPages, { pageNumber });
    const ignoredPagesForCurrentPage = res?.data?.ignoredPagesForCurrentPage;
    return { ignoredPagesForCurrentPage, pageNumber };
  },
  getIgnoredPagesOnSearch: async (searchText) => {
    const res = await axios.post(endpoints.getIgnoredPagesBySearch, { searchText });
    const ignoredPagesBySearch = res?.data?.ignoredPagesBySearch;
    return ignoredPagesBySearch;
  },
  getAccountInformation: async () => {
    const serverRes = await axios.get(endpoints.getAccountInformation, { preventLoading: true });
    return serverRes?.data?.accountInfo;
  },
  changeAccountInformation: async (updatedAccountInfo) => {
    const serverRes = await axios.post(endpoints.changeAccountInformation, updatedAccountInfo, { preventLoading: true });
    if (serverRes.data && serverRes.data.success) {
      return { success: true };
    }
  },
  getFieldValuesByFieldKey: async (fieldKey) => {
    const serverRes = await axios.post(endpoints.getFieldValueByFieldKey, { fieldKey }, { preventLoading: true });
    const userFields = serverRes?.data?.userFields;
    return { userFields, fieldKey };
  }
};

export default apiServices;
