import React from "react";
import Carousel from "./Carousel";
import { Rate } from "antd";
import { hardCodedComments } from "../../../services/utils";

export default function CommentsSection() {
  return (
    <div className="comments-section-wrapper">
      <Carousel>
        {hardCodedComments.map((comment, index) => (
          <div key={index} className="display-flex-column comment-wrapper">
            <Rate className="comment-rating-stars" disabled allowHalf value={comment.rating} />
            <div className="comment-user-name">
              <b>{comment.name}</b>
            </div>
            <div className="comment-text">{comment.comment}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
