import { createSlice } from "@reduxjs/toolkit";

export const appSettings = createSlice({
  name: "appSettings",
  initialState: {
    isPhoneView: false,
    fillbazIframeSrc: "",
    isLoaderOn: true,
    fieldsDictionary: []
  },
  reducers: {
    setIsPhoneView: (state, action) => {
      state.isPhoneView = action.payload;
    },
    setFillbazIframeSrc: (state, action) => {
      state.fillbazIframeSrc = action.payload;
    },
    setLoaderOn: (state, action) => {
      state.isLoaderOn = action.payload;
    },
    setFieldsDictionary: (state, action) => {
      state.fieldsDictionary = action.payload;
    }
  }
});

export const { setIsPhoneView, setFillbazIframeSrc, setLoaderOn, setFieldsDictionary } = appSettings.actions;

export default appSettings.reducer;
