import { Modal } from "antd";
import React from "react";

export default function IgnoredPageModal({ handleIgnorePage, isIgnorePageModalOpen, fullUrl, setIsIgnorePageModalOpen }) {
  const handleOk = () => {
    handleIgnorePage();
  };

  const handleCancel = () => {
    setIsIgnorePageModalOpen(false);
  };

  return (
    <Modal okText="Approve" title="Ignoring a website" open={isIgnorePageModalOpen} onOk={handleOk} onCancel={handleCancel}>
      Are you sure that you want to mute the notifications for <b>{fullUrl}</b>?
    </Modal>
  );
}
