import React from "react";
import "./passwordChangeSuccess.css";
import wideLogo from "../../assets/pngs/fillbaz-logo-wide.png";

export default function PasswordChangeSuccess() {
  // const { isPhoneView } = useSelector((state) => state.appSettings);

  return (
    <div className="no-full-page-content">
      <div className="no-full-page-wrapper">
        <img className="password-change-element fillbaz-logo-header" src={wideLogo} alt="email verification fillbaz logo header" />
        <div className="password-change-element password-change-message">
          Your password has been successfully changed! You may login now with your new password.
        </div>
      </div>
    </div>
  );
}
