import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

export default function Carousel({ children: slides }) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(next, 5000);

    return () => clearInterval(slideInterval);
  }, []);

  const prev = () => setCurrent((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  const next = () => setCurrent((curr) => (curr === slides.length - 1 ? 0 : curr + 1));
  return (
    <div className="carousel-container">
      <div className="carousel-slides" style={{ transform: `translateX(-${current * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="carousel-slide" key={index}>
            {slide}
          </div>
        ))}
      </div>
      <div className="carousel-controls">
        <Button onClick={prev} shape="circle" style={{ border: "none" }} icon={<ArrowLeftOutlined />} />
        <Button onClick={next} shape="circle" style={{ border: "none" }} icon={<ArrowRightOutlined />} />
      </div>
    </div>
  );
}
