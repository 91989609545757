import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { setIsPhoneView } from "./store/reducers/appSettings";
import { ConfigProvider } from "antd";
import { injectExtensionFont } from "./services/utils";
import PageAfterInstall from "./pages/PageAfterInstall/PageAfterInstall";
import EmailConfirmed from "./pages/EmailConfirmed/EmailConfirmed";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import PasswordChangeSuccess from "./pages/PasswordChangeSuccess/PasswordChangeSuccess";
import LoaderWrapper from "./widgets/LoaderWrapper";
import useBrowserMessageListener from "./customHooks/useBrowserMessageListener";
import useIframeInjectionChecker from "./customHooks/useIframeInjectionChecker";
import MyInfoStored from "./pages/MyInfoStored/MyInfoStored";
import ProtectedRoute from "./widgets/ProtectedRoute";
import IgnoredPages from "./pages/IgnoredPages/IgnoredPages";
import MyAccount from "./pages/MyAccount/MyAccount";

function App() {
  const dispatch = useDispatch();
  const isPhoneView = useMediaQuery("(max-width: 600px)"); // Adjust the breakpoint as needed
  dispatch(setIsPhoneView(isPhoneView));

  useBrowserMessageListener();
  useIframeInjectionChecker();

  return (
    <div className="options-page-wrapper">
      <LoaderWrapper>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#2759cd"
            }
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<PageAfterInstall />}></Route>
              <Route exact path="/welcome" element={<PageAfterInstall />}></Route>
              <Route exact path="/" element={<ProtectedRoute />}>
                <Route exact path="/my-info-stored" element={<MyInfoStored />} />
                <Route exact path="/ignored-websites" element={<IgnoredPages />} />
                <Route exact path="/my-account" element={<MyAccount />} />
              </Route>
              <Route exact path="/email-confirmed" element={<EmailConfirmed />}></Route>
              <Route exact path="/reset-password" element={<ForgotPassword />}></Route>
              <Route exact path="/reset-password-success" element={<PasswordChangeSuccess />}></Route>
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </LoaderWrapper>
    </div>
  );
}

injectExtensionFont();
export default App;
