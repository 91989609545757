import React, { useState } from "react";
import { AutoComplete, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import FieldOptionDeleteModal from "./FieldOptionDeleteModal";
import { useMutation } from "@tanstack/react-query";
import apiServices from "../../../services/services";
import { v4 as uuidv4 } from "uuid";
import useAntdMessage from "../../../customHooks/useAntdMessage";

export default function UserFieldRow({ row }) {
  const showMessage = useAntdMessage();
  const { key: fieldKey, friendlyText, values } = row;
  const fieldValueOptions = values.map((value) => ({
    label: (
      <div key={value} className="user-field-row-value-option">
        {value?.length > 20 ? (
          <Tooltip title={value}>
            <span>{value.substring(0, 37)}...</span>
          </Tooltip>
        ) : (
          <span>{value}</span>
        )}
        <Button onClick={() => handleDeleteFieldValueOption(value)} icon={<DeleteOutlined />} />
      </div>
    ),
    value
  }));

  const { userProfiles } = useSelector((state) => state.userSettings);
  const defaultSelectedProfile = userProfiles.find((profile) => profile.isMainProfile);

  const [fieldValues, setFieldValues] = useState(fieldValueOptions);
  const [selectedFieldValue, setSelectedFieldValue] = useState(values[0]);
  const [isFilteringFieldValueOptions, setIsFilteringFieldValueOptions] = useState(false);
  const [isFieldOptionDeleteModelOpen, setIsFieldOptionDeleteModelOpen] = useState(false);
  const [isAddingNewFieldValue, setIsAddingNewFieldValue] = useState(false);
  const [isNewFieldValueAdded, setIsNewFieldValueAdded] = useState(false);

  function handleInputBlur() {
    if (!selectedFieldValue) {
      setSelectedFieldValue(values[0]);
      setIsAddingNewFieldValue(false);
    }
    setIsFilteringFieldValueOptions(false);
  }

  function handleInputSearch() {
    setIsFilteringFieldValueOptions(true);
  }

  function handleDeleteFieldValueOption() {
    setIsFieldOptionDeleteModelOpen(true);
  }

  const handleFieldValueChange = (newValue) => {
    setSelectedFieldValue(newValue);
    const doesOptionExistInValues = fieldValues.find((value) => value.value === newValue);
    setIsAddingNewFieldValue(!doesOptionExistInValues);
    setIsNewFieldValueAdded(false);
  };

  function renderCorrectButton() {
    if (isAddingNewFieldValue && selectedFieldValue) {
      return (
        <Button type="primary" onClick={handleAddNewField}>
          Add new
        </Button>
      );
    } else if (isNewFieldValueAdded) {
      return <Button disabled>Added</Button>;
    } else {
      return <></>;
    }
  }

  const addNewUserFieldValue = useMutation((newFieldData) => apiServices.addNewUserFieldValue(newFieldData, defaultSelectedProfile), {
    onSuccess: (data) => {
      const fieldValuesClone = [...fieldValues];
      const newFieldOption = {
        label: (
          <div key={uuidv4()} className="user-field-row-value-option">
            <span>{selectedFieldValue}</span>
            <Button onClick={() => handleDeleteFieldValueOption(selectedFieldValue)} icon={<DeleteOutlined />} />
          </div>
        ),
        value: selectedFieldValue
      };
      fieldValuesClone.push(newFieldOption);
      setFieldValues(fieldValuesClone);
    },
    onError: () => {
      showMessage("error", "Something went wrong while adding a new field value, please try again later.");
    }
  });

  const handleAddNewField = () => {
    addNewUserFieldValue.mutate({ value: selectedFieldValue, key: fieldKey });
    setIsAddingNewFieldValue(false);
    setIsNewFieldValueAdded(true);
  };

  return (
    <>
      <div className="display-flex-row full-width user-field-row-wrapper">
        <div className="select-tag-wrapper-user-field-row-key">
          {friendlyText?.length > 30 ? (
            <Tooltip title={friendlyText}>
              <div className="user-field-row-key">{friendlyText.substring(0, 37)}...</div>
            </Tooltip>
          ) : (
            <div className="user-field-row-key">{friendlyText}</div>
          )}
        </div>
        <div className="autocomplete-tag-wrapper-user-field-row-value">
          {
            <AutoComplete
              allowClear
              filterOption={(input, option) =>
                isFilteringFieldValueOptions ? (option?.value ?? "").toLowerCase().includes(input.toLowerCase()) : true
              }
              defaultValue={values[0]}
              value={selectedFieldValue}
              onChange={(value) => handleFieldValueChange(value)}
              onSearch={handleInputSearch}
              onBlur={handleInputBlur}
              className="full-width"
              options={fieldValues}
              dropdownRender={(menu) => <div className="user-field-row-value-dropdown-render">{menu}</div>}
            />
          }
        </div>

        <div className="user-field-row-buttons-wrapper">{renderCorrectButton()}</div>
        <FieldOptionDeleteModal
          isFieldOptionDeleteModelOpen={isFieldOptionDeleteModelOpen}
          setIsFieldOptionDeleteModelOpen={setIsFieldOptionDeleteModelOpen}
          value={selectedFieldValue}
          fieldKey={fieldKey}
          friendlyText={friendlyText}
          fieldValues={fieldValues}
          setFieldValues={setFieldValues}
        />
      </div>
    </>
  );
}
