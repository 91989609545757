import React from "react";
import { Button } from "antd";
import welcomePageImg from "../../../assets/svgs/options-welcoming-img.svg";
import fillbazLogoWithText from "../../../assets/svgs/fillbaz-logo-with-text.svg";
import { handleLoginSignupComponentRender } from "../../../services/utils";
import { useSelector } from "react-redux";
import { fillbazExtensionChromeWebstoreUrl } from "../../../config";

export default function CongratsInstallingFillbaz() {
  const { fillbazIframeSrc } = useSelector((state) => state.appSettings);
  const { isLoggedIn } = useSelector((state) => state.userSettings);

  let reminderElement = "";

  if (fillbazIframeSrc && isLoggedIn) {
    reminderElement = <></>;
  } else if (fillbazIframeSrc) {
    reminderElement = (
      <>
        <span onClick={() => handleLoginSignupComponentRender("signup")} className="underline-txt apply-cursor-pointer">
          Signup with your email{" "}
        </span>
        <span className="apply-black-text">or </span>
        <span onClick={() => handleLoginSignupComponentRender("login")} className="underline-txt apply-cursor-pointer">
          login
        </span>
      </>
    );
  } else if (!fillbazIframeSrc || !isLoggedIn) {
    reminderElement = (
      <>
        🚀 Ready to supercharge your browsing experience?
        <br />
        <span onClick={() => window.open(fillbazExtensionChromeWebstoreUrl, "_blank")} className="underline-txt apply-cursor-pointer">
          Install Fillbaz NOW!
        </span>
      </>
    );
  }

  return (
    <div className="options-page-congrats-message-wrapper display-flex-row">
      <img className="options-page-congrats-message-img" src={welcomePageImg} alt="Welcome to Fillbaz" />
      <div className="congrats-message-and-instructions display-flex-column">
        <img className="fillbaz-logo-with-text" src={fillbazLogoWithText} alt="Fillbaz logo" />
        <div className="options-page-congrats-message display-flex-column">
          <span className="congrats-msg-header">CONGRATULATIONS</span>
          <span className="congrats-msg-body">You are ready to</span>
          <span className="congrats-msg-body">Fill forms Automatically</span>
          <span className="congrats-msg-body">
            at <span className="high-speed-txt">High Speed</span>
          </span>
        </div>
        <div className="what-we-offer display-flex-column">
          <span className="what-we-offer-item">✅Free forever</span>
          <span className="what-we-offer-item">✅Unlimited usage</span>
          <span className="what-we-offer-item">✅No setup required</span>
        </div>
        {fillbazIframeSrc && !isLoggedIn ? (
          <Button disabled onClick={() => console.log("Google btn click")} type="primary">
            Google Authentication Coming Soon...
          </Button>
        ) : (
          <></>
        )}
        <div className="full-width sigup-or-login-span-wrapper">{reminderElement}</div>
      </div>
    </div>
  );
}
