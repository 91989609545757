import React, { useState } from "react";
import LoginSignupBtns from "../PageAfterInstall/subcomponents/LoginSignupBtns";
import fillbazLogo from "../../assets/pngs/fillbaz-logo-wide.png";
import "./myInfoStored.css";
import { useDispatch, useSelector } from "react-redux";
import UserFieldRow from "./subcomponents/UserFieldRow";
import { Button, Empty, Pagination } from "antd";
import { renderedItemsPerPage } from "../../config";
import { useMutation } from "@tanstack/react-query";
import apiServices from "../../services/services";
import { setUserFields } from "../../store/reducers/userSettings";
import Search from "antd/es/input/Search";
import useAntdMessage from "../../customHooks/useAntdMessage";
import { PlusOutlined } from "@ant-design/icons";
import AddNewFieldModal from "./subcomponents/AddNewFieldModal";
import { v4 as uuidv4 } from "uuid";

export default function MyInfoStored() {
  const dispatch = useDispatch();
  const showMessage = useAntdMessage();
  const { userFields, userFieldsAmount } = useSelector((state) => state.userSettings);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchModeOn, setSearchModeOn] = useState(false);
  const [addNewFieldModalOn, setAddNewFieldModalOn] = useState(false);

  const getUserFieldsNextPage = useMutation((pageNumber) => apiServices.getNextPageFields(pageNumber), {
    onSuccess: (data) => {
      const { userFieldsForCurrentPage, pageNumber } = data;
      setCurrentPage(pageNumber);

      setSearchModeOn(false);
      dispatch(setUserFields(userFieldsForCurrentPage));
    },
    onError: (err) => {
      showMessage("error", "Something went wrong while fetching next page data, please try again later.");
    }
  });

  const getUserFieldsForSearch = useMutation((searchText) => apiServices.getUserFieldsForSearch(searchText), {
    onSuccess: (data) => {
      setSearchModeOn(true);
      dispatch(setUserFields(data));
    },
    onError: (err) => {
      showMessage("error", "Something went wrong while executing your search, please try again later.");
    }
  });

  const handlePageChange = (page) => {
    getUserFieldsNextPage.mutate(page);
  };

  const handleFieldSearch = (searchText) => {
    if (searchText.length > 1) {
      getUserFieldsForSearch.mutate(searchText);
    } else {
      getUserFieldsNextPage.mutate(1);
    }
  };

  const handleAddNewField = () => {
    setAddNewFieldModalOn(true);
  };

  return (
    <div className="my-info-stored-wrapper">
      <div className="my-info-stored-header-wrapper">
        <img className="my-info-stored-fillbaz-logo" src={fillbazLogo} alt="fillbaz-logo" />
        <LoginSignupBtns currentNavItem={"my-info-stored"} />
      </div>
      <div className="display-flex-row my-info-stored-seacrh-and-add-field">
        <div className="display-flex-row">
          <h3 className="my-info-stored-h3 apply-main-color">My Info Stored</h3>
          <Search placeholder="Search for a field" style={{ width: 200 }} onSearch={(searchText) => handleFieldSearch(searchText)} />
        </div>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNewField}>
          Add new field
        </Button>
      </div>

      <div className="my-info-stored-content-wrapper">
        {userFields?.length ? (
          userFields.map((field) => <UserFieldRow key={uuidv4()} row={field} />)
        ) : (
          <Empty className="no-field-found-msg" description={"No field found"} />
        )}
      </div>
      {searchModeOn ? (
        <></>
      ) : (
        <Pagination
          className="my-info-stored-pagination"
          simple
          total={userFieldsAmount}
          showSizeChanger={false}
          current={currentPage}
          pageSize={renderedItemsPerPage}
          onChange={handlePageChange}
        />
      )}
      <AddNewFieldModal
        handleFieldSearch={handleFieldSearch}
        addNewFieldModalOn={addNewFieldModalOn}
        setAddNewFieldModalOn={setAddNewFieldModalOn}
        setSearchModeOn={setSearchModeOn}
      />
    </div>
  );
}
