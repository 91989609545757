import React from "react";
import LoginSignupBtns from "../PageAfterInstall/subcomponents/LoginSignupBtns";
import fillbazLogo from "../../assets/pngs/fillbaz-logo-wide.png";
import "./myAccount.css";
import { useDispatch } from "react-redux";
import useAntdMessage from "../../customHooks/useAntdMessage";
import apiServices from "../../services/services";
import { Button, Input } from "antd";
import { setUserAccountInfo } from "../../store/reducers/userSettings";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export default function MyAccount() {
  const dispatch = useDispatch();
  const showMessage = useAntdMessage();
  const queryClient = useQueryClient();

  const { data, isError } = useQuery({ queryKey: ["accountInfo"], queryFn: apiServices.getAccountInformation });
  if (isError) showMessage("error", "Something went wrog shile fetching your account information, please try again later.");

  const userFullNameExtracted = data?.firstName && data?.lastName ? `${data.firstName} ${data.lastName}` : "";

  function handleDataChange(e, changedValueParam) {
    const accountInfoClone = { ...data };
    accountInfoClone[changedValueParam] = e.target.value;
    queryClient.setQueryData(["accountInfo"], accountInfoClone);
  }

  async function handleSaveAccountChanges() {
    try {
      const serverRes = await apiServices.changeAccountInformation(data);
      if (serverRes?.success) {
        showMessage("success", "Success!");
        dispatch(setUserAccountInfo(data));
      } else {
        showMessage("error", "Couldn't update your account info, please try again later");
      }
    } catch (error) {
      showMessage("error", "Couldn't update your account info, please try again later");
    }
  }

  return (
    <div className="my-account-wrapper">
      <div className="my-account-header-wrapper">
        <img className="my-account-fillbaz-logo" src={fillbazLogo} alt="fillbaz-logo" />
        <LoginSignupBtns currentNavItem={"my-account"} />
      </div>
      <h3 className="my-account-h3 apply-main-color">My Account</h3>
      <div className="my-account-row">
        <div className="my-account-row-label">Name</div>
        <Input placeholder="John Doe" value={data?.fullName || userFullNameExtracted} onChange={(e) => handleDataChange(e, "fullName")} />
      </div>
      <div className="my-account-row">
        <div className="my-account-row-label">Email</div>
        <Input placeholder="johndoe@gmail.com" value={data?.email} type="email" disabled onChange={(e) => handleDataChange(e, "email")} />
      </div>
      <div className="my-account-row">
        <div className="my-account-row-label">Phone number</div>
        <Input placeholder="+1 758 345 0203" value={data?.phone} onChange={(e) => handleDataChange(e, "phone")} />
      </div>
      <div className="my-account-buttons-wrapper">
        <Button onClick={handleSaveAccountChanges} className="my-account-save-changes-button" type="primary">
          Save Changes
        </Button>
        <Button className="my-account-reset-password-button" type="text" disabled>
          Reset Password
        </Button>
      </div>
    </div>
  );
}
