import { createSlice } from "@reduxjs/toolkit";

export const userSettings = createSlice({
  name: "userSettings",
  initialState: {
    isLoggedIn: true,
    userAuthToken: "",
    userFields: [],
    userFieldsAmount: 0,
    userIgnoredPagesAmount: 0,
    userIgnoredPages: [],
    userAccountInfo: {},
    userProfiles: []
  },
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserAuthToken: (state, action) => {
      state.userAuthToken = action.payload;
    },
    setUserFields: (state, action) => {
      state.userFields = action.payload;
    },
    setUserFieldsAmount: (state, action) => {
      state.userFieldsAmount = action.payload;
    },
    setUserIgnoredPagesAmount: (state, action) => {
      state.userIgnoredPagesAmount = action.payload;
    },
    setUserIgnoredFields: (state, action) => {
      state.userIgnoredPages = action.payload;
    },
    setUserAccountInfo: (state, action) => {
      state.userAccountInfo = action.payload;
    },
    setUserProfiles: (state, action) => {
      state.userProfiles = action.payload;
    }
  }
});

export const {
  setIsLoggedIn,
  setUserAuthToken,
  setUserFields,
  setUserIgnoredFields,
  setUserAccountInfo,
  setUserFieldsAmount,
  setUserProfiles,
  setUserIgnoredPagesAmount
} = userSettings.actions;

export default userSettings.reducer;
