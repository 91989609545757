import { configureStore } from "@reduxjs/toolkit";
import appSettingsReducer from "./reducers/appSettings";
import userSettingsReducer from "./reducers/userSettings";

export default configureStore({
  reducer: {
    appSettings: appSettingsReducer,
    userSettings: userSettingsReducer
  }
});
