import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

export default function LoaderWrapper({ children }) {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const { isLoaderOn } = useSelector((state) => state.appSettings);

  return (
    <>
      {isFetching || isMutating || isLoaderOn ? (
        <div className="custom-loader">
          <div className="custom-loader-position">
            <LoadingOutlined style={{ fontSize: 130 }} spin />
          </div>
        </div>
      ) : (
        <></>
      )}
      {children}
    </>
  );
}
