import React from "react";
import twoBillionIcon from "../../../assets/svgs/2billion.svg";
import applyJobsIcon from "../../../assets/svgs/apply-jobs.svg";
import carRentIcon from "../../../assets/svgs/car-rent.svg";
import loanIcon from "../../../assets/svgs/loan.svg";
import taxIcon from "../../../assets/svgs/tax.svg";
import translateIcon from "../../../assets/svgs/translate.svg";
import { v4 as uuidv4 } from "uuid";

export default function WhyToUseFillbaz() {
  const whyUseExtensionIcons = [
    { icon: applyJobsIcon, msg: "Apply for employement", key: uuidv4() },
    { icon: loanIcon, msg: "Loan Applications", key: uuidv4() },
    { icon: taxIcon, msg: "Treasury documentation", key: uuidv4() },
    { icon: translateIcon, msg: "Auto-fill in all world languages", key: uuidv4() },
    { icon: carRentIcon, msg: "Car Rental Forms", key: uuidv4() },
    { icon: twoBillionIcon, msg: "Web around the globe", key: uuidv4() }
  ];

  return (
    <>
      <div className="why-users-use-this-extension">Thousands of users use it to:</div>
      <div className="why-users-use-this-extension-icons-wrapper display-flex-row">
        {whyUseExtensionIcons.map((iconData) => (
          <div key={iconData.key} className="why-users-use-this-extension-icon-wrapper display-flex-column">
            <img className="why-users-use-this-extension-icon" src={iconData.icon} alt="small icon" />
            <div className="why-users-use-this-extension-text">{iconData.msg}</div>
          </div>
        ))}
      </div>
    </>
  );
}
