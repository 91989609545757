import { useCallback } from "react";
import { message } from "antd";

const useAntdMessage = () => {
  const openMessage = useCallback((type, content, onCloseCallback) => {
    message.open({
      type,
      content,
      onClose: () => {
        // This callback will be called when the message is closed
        if (onCloseCallback) {
          onCloseCallback();
        }
      }
    });
  }, []);

  return openMessage;
};

export default useAntdMessage;
