import { Button } from "antd";
import React from "react";
import { handleLoginSignupComponentRender } from "../../../services/utils";
import { useSelector } from "react-redux";
import PersonalAreaNavMenu from "../../../widgets/PersonalAreaNavMenu";
import { fillbazExtensionChromeWebstoreUrl } from "../../../config";

export default function LoginSignupBtns({ currentNavItem = "welcome" }) {
  const { fillbazIframeSrc } = useSelector((state) => state.appSettings);
  const { isLoggedIn } = useSelector((state) => state.userSettings);
  let buttons = {};

  if (fillbazIframeSrc && isLoggedIn) {
    buttons.one = <></>;
    buttons.two = <PersonalAreaNavMenu currentNavItem={currentNavItem} />;
  } else if (fillbazIframeSrc) {
    buttons.one = (
      <Button
        className="login-signup-btn"
        onClick={() => handleLoginSignupComponentRender("login")}
        type="text"
        style={{ color: "#2759cd" }}
      >
        Login
      </Button>
    );

    buttons.two = (
      <Button onClick={() => handleLoginSignupComponentRender("signup")} type="primary">
        Sign up
      </Button>
    );
  } else if (!fillbazIframeSrc || !isLoggedIn) {
    buttons.one = <></>;
    buttons.two = (
      <Button onClick={() => window.open(fillbazExtensionChromeWebstoreUrl, "_blank")} type="primary">
        Install Fillbaz Now!
      </Button>
    );
  }

  return (
    <div className="login-signup-btns-wrapper-options">
      {buttons.one}
      {buttons.two}
    </div>
  );
}
